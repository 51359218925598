.slideshow-container {
    overflow: hidden;
  }
 
  .slides {
    display: flex;
    transition: transform 1s ease-in-out;
  }
 
  .slide {
    flex: 0 0 auto;
    margin: 10px;
  }
 
  .slide:last-child {
    margin-right: 0;
  }
 
  .slide img {
    width: auto;
    height: 270px; 
  }
 
  .slide-title {
    color:#222;
    margin-top: 5px; 
    text-align: center;
  
    
  }
 
  .active {
    opacity: 1;
  }
 
  