.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgb(0, 0, 0) rgb(250, 250, 250); /* For Firefox */
   
    &::-webkit-scrollbar {
      width: 10px; /* Width of the main scrollbar */
    }
   
    &::-webkit-scrollbar-thumb {
      background-color: rgb(255, 0, 0); /* Color of the thumb */
      border-radius: 5px; /* Radius of the thumb */
    }
   
    &::-webkit-scrollbar-track {
      background-color: rgb(0, 0, 0); /* Color of the track */
    }
  }
   