*{
  font-family: Macan,Helvetica Neue,Helvetica,Arial,sans-serif;
}


.footer {
  position: relative;
 padding-top:30px;
 padding-bottom:30px;
  width: 100%;
  color: black;
  text-align: center;
}

#vidd{
  background-color:rgb(222, 228, 241);
  height:400px;
  width:100%;
  border-radius:80px;
  margin-top:60px;
  margin-left:5px;
  padding-bottom:15px;
}
#viddbtn:hover{
background-color:aqua;
}
/* .underln {
  position: relative;
  text-align: center;
  padding-bottom:15px; 
}

.underln:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 3px solid hwb(0 21% 8%);
  width: 10%;
} */

/* 
.card.card-tile {
  position: relative;
}

.card-tile::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%; 
  height: 2px;
  background-color: hsl(0, 95%, 56%);
  transform: translateX(-50%); 
  transition: width 0.3s;
} */

/* 
.card:hover .card-tile::before {
  width: 100%; 
} */

.navbar-collapse .navbar-nav {
  align-items: center; 
}


.contactUs{
  position:relative;
  width:100%;
  padding:40px 100px;
}
.contactUs .title{
  display:flex;
  justify-content: center;
  align-items: center;
}


.form{
  grid-area:form;
}
.info{
  grid-area:info;
}
.map{
  grid-area:map;
}
.contact1{
  padding:10px;
  background:#ffff;
 
}
.box{
  position:relative;
  display:grid;
  grid-template-columns: 4fr 4fr;
  grid-template-rows: 3fr 3fr;
  grid-template-areas: 
  "form info" 
  "form map";
  grid-gap:20px;
  margin-top:20px;
}
.map iframe{
  width:100%;
  height:100%;
}



.card-container1{
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  transition: transform 0.5s;
}

.card1 {
  width: 100%; 
  transform-style: preserve-3d;
}

.card-middle {
  z-index: 1;
  
}

.card-container1:hover {
  transform: scale(0.8); 
}



 
.container3 {
  display: flex;
  flex-wrap: wrap;
}
 
.panel {
  flex: 1;
  min-height: 350px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: flex 0.5s ease, box-shadow 0.3s ease;
  background-size: cover;
  background-position: center;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
 
.panel h4 {
  position: absolute;
  bottom: 20px;
  left: 10px;
  margin: 0;
}
 
.panel.active {
  flex: 5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button{
  -webkit-appearance: none;
}





.heading {
  text-align: center;
  color:#ffff;
  
}
.buttons{
  text-align: center;
}

.content p{
  color:#eeee;
  justify-content: center;
  text-align: center;
  line-height: 27px;
  word-spacing:1px;
 

}

.fade-in {
  opacity: 0; /* Initially set opacity to 0 */
  transform: translateY(150px); /* Optionally, you can add a slight vertical offset */
  transition: opacity 0.8s ease, transform 0.6s ease;
}

.fade-in.in-view {
  opacity: 1;
  transform: translateY(0);
}
 




















@media screen and (max-width:990px){
  .tmwrmedia{
    align-items:center;
  padding-top:20px;
     width:100%;
     height:500px;
  }
  }

 @media screen and (min-width:990px){
  .joingigcontent{
 padding-left:20px;
 
  }
   }
  
 
@media screen and (max-width: 990px) {
  .tmwrmedia {
    align-items: center;
    padding-top: 20px;
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width: 412px) {
 .contactUs .title{
  text-align: center;
 }
 .box{
justify-content: center;
align-items:center;
 }
 .contact1 .form{
  width:100%;
 }
}

@media screen and (max-width: 700px) {
  .box {
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
    grid-template-areas: 
      "form"
      "info"
      "map";
  }

  .form,
  .info,
  .map {
    margin-top: 0;
    padding: 15px; 
  }
}

@media (max-width: 600px) {
  .contact1.form {
    position: relative;
    right:8%;
      width: 120%; 
  }
  .contact1.info{
    position: relative;
    right:8%;
    width:120%;
  }
  .contact1.map{
    position: relative;
    right:8%;
    width:120%;
  }
}

@media (max-width: 500px) {
  .contact1.form{
    position: relative;
    right:22%;
    width:150%;
  }
  .contact1.info{
    position: relative;
    right:22%;
    width:150%;
  }
  .contact1.map{
    position: relative;
    right:22%;
    width:150%;
  }
}
@media (max-width: 419px) {
  .contact1.form{
    position: relative;
    right:30%;
    width:165%;
  }
  .contact1.info{
    position: relative;
    right:30%;
    width:165%;
  }
  .contact1.map{
    position: relative;
    right:30%;
    width:165%;
  }
}

